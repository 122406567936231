.tab {
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  margin: 0px 15px;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: #efefef;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 2px 20px;
  transition: 0.3s;
  font-size: 14px;
  border: 1px solid #efefef;
  margin-right: 1px;
  border-radius: 2px;
  color: #111;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #111;
  color: #fff;
}
.boder1{
  border-top: 1px solid #d2d2d2;
  margin: 23px 0px;
}
.be-form-add-anthor-section{
  margin-top:10px;
}

.switch-field {
  display: initial;
  margin-left: 13px;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #e4e4e4;
  color: rgb(0 0 0 / 87%);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  /* border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out; */
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #45AEB2;
  box-shadow: none;
  color:white;
}

.switch-field label:first-of-type {
  /* border-radius: 4px 0 0 4px; */
}

.switch-field label:last-of-type {
  /* border-radius: 0 4px 4px 0; */
}

.css-10wo9uf-option {
  color: black !important;
}
.be_form_loyalty_label_40{
  width:40%!important;
}
.be_form_loyalty_label_35{
  width:35%!important;
}
.input-joint-color {
  background: #9EDFED!important;
}