.tab {
  overflow: hidden;
  border-bottom: 1px solid #d9d9d9;
  margin: 0px 15px;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: #efefef;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 2px 20px;
  transition: 0.3s;
  font-size: 14px;
  border: 1px solid #efefef;
  margin-right: 1px;
  border-radius: 2px;
  color: #111;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #111;
  color: #fff;
}
.boder1{
  border-top: 1px solid #d2d2d2;
  margin: 23px 0px;
}
.be-form-add-anthor-section{
  margin-top:10px;
}

.switch-field {
  display: initial;
  margin-left: 13px;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #e4e4e4;
  color: rgb(0 0 0 / 87%);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  /* border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out; */
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #45AEB2;
  box-shadow: none;
  color:white;
}

.switch-field label:first-of-type {
  /* border-radius: 4px 0 0 4px; */
}

.switch-field label:last-of-type {
  /* border-radius: 0 4px 4px 0; */
}

.css-10wo9uf-option {
  color: black !important;
}
.be_form_loyalty_label_40{
  width:40%!important;
}
.be_form_loyalty_label_35{
  width:35%!important;
}
.input-joint-color1 {
  background: #9EDFED!important;
}
.remov-button {
  border: none;
  position: absolute;
  left: 17px;
 top:5px;
}

.info-tag-new{
  position: relative;
    /* display: inherit; */
    display: inline-flex;
    background: #dbdbdb;
    padding: 0px 6px;
    border-radius: 9px;
    font-size: 9px;
    color: #fff;
    left: 5px;
    bottom: 1px;
}
.tooltiptext-new{
  visibility: hidden; 
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding:2px 4px 2px 4px;  
   text-align: inherit;
    position: absolute;
    z-index: 1;
    /* top: 24px; */
    display: flex;
    justify-content: center;
    /* left: 0%; */
    font-size: 11px !important;
    /* min-width: max-content; */
    /* white-space: nowrap; */
    width: fit-content;
}
.info-tag-new:hover + .tooltiptext-new{
visibility: visible!important;
}
.label_image_crop{
  font-size: 12px;
}
.tooltiptext-new_loyal {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px 4px 2px 4px;
  text-align: inherit;
  position: absolute;
  z-index: 1;
  top: 34px;
  display: flex;
  justify-content: center;

  font-size: 11px !important;

  width: -moz-fit-content;
  width: fit-content;
}
.info-tag-new:hover + .tooltiptext-new_loyal{
  visibility: visible!important;
  }
  .loyalty_submit_confirm{
    padding: 18px 16px;
    background: #d5e9db;
    text-align: center;
  }
  .spinload_ce{
  text-align: center;
  height:200px;
  margin-top:30px;
  margin-bottom:10px;
  }
  .modalBackground-new {
    width: 100vw;
    height: 100vh;
    /* background-color: rgb(10 10 10 / 12%); */
    /* background-color: rgb(10 10 10 / 77%); */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: start;
    top: 11%;
    left: 0%;
    z-index: 99999;
  }
  .modalContainer4-new {
    width: 500px;
    height: auto;
    border-radius: 29px 29px 29px 29px;
    background-color: #008080;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 0px 10px 15px 1px;
  }
  .modalContainer4-new1 {

    width: 341px;
    height: auto;
    border-radius: 29px 29px 29px 29px;
    background-color: #008080;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 0px 10px 15px 1px;
  }
  .modalContainer4-new  .title{
  display: inline-block;
  text-align: center;
  margin-top: 10px;
  color: black;
    font-size: medium;
    /* margin-bottom: 20px; */
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 0px 17px;
}
.modalContainer4-new .title svg {
  margin-right: 10px;
  font-size: 23px;
}
.modalContainer4-new1  .title{
  display: inline-block;
  text-align: center;
  margin-top: 10px;
  color: black;
    font-size: medium;
    /* margin-bottom: 20px; */
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 0px 17px;
}
.modalContainer4-new1 .title svg {
  margin-right: 10px;
  font-size: 23px;
}
.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}
.cancel-model-content1-new {
  font-size: 20px;
  margin-bottom: 0px;
  margin-right: 15px;
  font-size: 17px;
  color: rgb(251 248 248);
  font-weight: 400;
  line-height: 20px;
}
.titleCloseBtn svg {
  margin-right: 0px;
}
.toastprogress {
  animation-duration: 5000ms;
  animation-play-state: paused;
  opacity: 1;
  background: #0726bc85 !important;
  animation: Toastify__trackProgress linear 1 forwards;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: hsla(0, 0%, 100%, 0.7);
  -webkit-transform-origin: left;
  transform-origin: left;
}
.tick_sv{
  font-size: 35px!important;
}
.info-tag_1{
  position: relative;
  background: #9ce8ea;;
  padding: 0px 6px;
  border-radius: 9px;
  font-size: 9px;
  color: #44aeb2;
  left: 5px;
  bottom: 1px;

    display: inline-flex;
    border-radius: 9px;
}
.tooltiptext_1{
  visibility: hidden; 
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding:2px 4px 2px 4px;  
   text-align: inherit;
    position: absolute;
    z-index: 1;
    /* top: 24px; */
    display: flex;
    justify-content: center;
    /* left: 0%; */
    font-size: 11px !important;
    /* min-width: max-content; */
    /* white-space: nowrap; */
    width: fit-content;
}
.info-tag_1:hover + .tooltiptext_1{
visibility: visible!important;
}