.approve_success_model{
display: flex;
justify-content: center;
}
.approve_success_model .success_approve{
   color: #008080!important;
}

.approve_success_model_h3{
    color: #9c99a0;
    padding: 0px 20px;
}

.alert_approve{
    /* margin-top:25px; */
    font-size:25px;
    font-family:sans-serif;
    text-align:center;
    width:300px;
    padding-top: 120px;
    position:relative;
    border-radius: 2%;
  }
  
.alert_approve::before{
    width:100px;
    height:100px;
    position:absolute;
    border-radius: 100%;
    inset: 20px 0px 0px 100px;
    font-size: 60px;
    line-height: 100px;
    border : 5px solid gray;
    animation-name: reveal;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
  }
  
.alert_approve>.alert-body{
    opacity:0;
    animation-name: reveal-message;
    animation-duration:1s;
    animation-timing-function: ease-out;
    animation-delay:1.5s;
    animation-fill-mode:forwards;
  }
  
  @keyframes reveal-message{
    from{
      opacity:0;
    }
    to{
      opacity:1;
    }
  }
  

  
  .success_approve::before{
    content: '✓';
    background-color: #eff;
    box-shadow: 0px 0px 12px 7px #06c4c4 inset;
    border : 5px solid #008080;
  }
  
  
  
  @keyframes reveal {
    0%{
      border: 5px solid transparent;
      color: transparent;
      box-shadow: 0px 0px 12px 7px rgba(255,250,250,0.8) inset;
      transform: rotate(1000deg);
    }
    25% {
      border-top:5px solid gray;
      color: transparent;
      box-shadow: 0px 0px 17px 10px rgba(255,250,250,0.8) inset;
      }
    50%{
      border-right: 5px solid gray;
      border-left : 5px solid gray;
      color:transparent;
      box-shadow: 0px 0px 17px 10px rgba(200,200,200,0.8) inset;
    }
    75% {
      border-bottom: 5px solid gray;
      color:gray;
      box-shadow: 0px 0px 12px 7px rgba(200,200,200,0.8) inset;
      }
    100%{
      border: 5px solid gray;
      box-shadow: 0px 0px 12px 7px rgba(200,200,200,0.8) inset;
    }
  }
  